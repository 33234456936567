

































































import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import axios, { CancelTokenSource } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { Pageable, Page } from '@/models';
import { CentroDeCusto } from '@/models/financeiro/Titulo'
import { FindCentroDeCustoUseCase } from '@/usecases/titulos/FindCentroDeCustoUseCase';
import DialogoDeEdicaoDeCentroDeCusto from '@/components/titulos/DialogoDeEdicaoDeCentroDeCusto.vue';
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'


@Component({
	components: {
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeCentroDeCusto,
		SeletorDeLojasDoUsuario,
	},
})

export default class TelaDeCentroCusto extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicaoDeCentroDeCusto!: DialogoDeEdicaoDeCentroDeCusto

	filtro: FiltroDeCentroDeCusto = {
		busca: '',
		lojaId: '',
	}
	carregando = false
	editando = false
	centroDeCusto: CentroDeCusto | null = null
	centrosDeCusto: CentroDeCusto[] = [];
	findCentroDeCustoUseCase = new FindCentroDeCustoUseCase()
	cancelToken: CancelTokenSource | null = null

	headers = [
		{ text: 'Centro de Custo', value: 'nome', sortable: false},
		{ text: 'Loja', value: 'lojaNome', sortable: false},
	]

	async buscar(paginavel: Pageable): Promise<Page<CentroDeCusto>> {
		if (this.cancelToken) this.cancelToken.cancel()
		this.cancelToken = axios.CancelToken.source()
		const parametrosFormatados = {
			...paginavel,
			busca: this.filtro.busca,
			lojaId: this.filtro.lojaId,
		}

		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		const page = await this.findCentroDeCustoUseCase.findCentroDeCusto(parametrosFormatados, axiosConfig)
		this.centrosDeCusto = page.content.map((centro) => ({
			...centro,
			lojaNome: centro.loja ? centro.loja.nomeFantasia : 'Sem Loja'}));		
		return page
	}


	abrirDialogoDeEditarCentroDeCusto(indice: number) {
		try {
			if(!this.temRecurso('alterar', "centro-de-custo")) {
				throw new Error('Necessário permissão para editar')
			}
			this.editando = true
			this.centroDeCusto = this.centrosDeCusto[indice]
			this.dialogoDeEdicaoDeCentroDeCusto.mostrar()
			this.dialogoDeEdicaoDeCentroDeCusto.$on('sucesso', this.buscar)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	abrirDialogoDeCriarCentroDeCusto() {
		try {
			if(!this.temRecurso('criar', 'centro-de-custo')) 
				throw new Error('Necessário permissão para criar')
			this.editando = false
			this.centroDeCusto = {
				id: '',
				nome: '',
			} as CentroDeCusto

			this.dialogoDeEdicaoDeCentroDeCusto.mostrar()
			this.dialogoDeEdicaoDeCentroDeCusto.$on('sucesso', this.buscar)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}

	@Watch('filtro.busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.filtro.busca,
		})
	}

	limparFiltros() {
		this.filtro = {
			busca: '',
			lojaId: '',
		};
		this.centrosDeCusto = [];
		this.dataTablePaginado.reiniciar({
			busca: this.filtro.busca,
			lojaId: this.filtro.lojaId,
		})
	}

}

export interface FiltroDeCentroDeCusto {
			busca: string | null
			lojaId: string | null
}
