





















































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {  obrigatorio } from '@/shareds/regras-de-form'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import { SaveCentroDeCustoUseCase } from '@/usecases/titulos/SaveCentroDeCustoUseCase'
import type { CentroDeCusto } from '@/models/financeiro/Titulo'

@Component({
	components: {
		SeletorDeLojasDoUsuario,
	},
})

export default class DialogoDeEdicaoDeCentroDeCusto extends Vue{
	@Ref() form!: HTMLFormElement
	@Prop({type: Object}) centroDeCusto?: CentroDeCusto
	mostra = false
	salvando = false

	saveCentroDeCustoUseCase = new SaveCentroDeCustoUseCase()

	obrigatorio = obrigatorio

	mostrar() {		
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}

	async salvar() {
		if (!this.centroDeCusto) return
		if (!this.form.validate()) return
		if(!this.centroDeCusto.id && (!this.centroDeCusto.loja)){
			AlertModule.setError('Necessário informar uma loja.')
			return
		}

		try {
			this.salvando = true 
		
			const centroDeCustoSalvo = !this.centroDeCusto.id
				? await this.saveCentroDeCustoUseCase.createCentroDeCusto(this.centroDeCusto)
				: await this.saveCentroDeCustoUseCase.updateCentroDeCusto(this.centroDeCusto)

			this.$emit('confirmado', centroDeCustoSalvo)
			this.mostra = false
			AlertModule.setSuccess('Centro de custo salvo com sucesso')
		} catch(error: any) {
			AlertModule.setError(error.response.data[0])
		} finally {
			this.salvando = false
			this.$emit('sucesso')
		}
	}
}
