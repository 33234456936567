import { Inject } from 'inversify-props'
import { AxiosRequestConfig } from 'axios'
import { CentroDeCusto } from '@/models/financeiro/Titulo'
import { Page } from '@/models/Page.js'
import type { CentroDeCustoServiceAdapter } from './adapter/CentroDeCustoServiceAdapter'

export class FindCentroDeCustoUseCase{
		@Inject('CentroDeCustoServiceAdapter')
		private centroDeCustoServiceAdapter!: CentroDeCustoServiceAdapter

		findCentroDeCusto = async (params?: any, config?: AxiosRequestConfig): Promise<Page<CentroDeCusto>> =>
			this.centroDeCustoServiceAdapter.findCentroDeCusto(params, config)
			
		findCentroDeCustoByLojaId = async (params?: any, config?: AxiosRequestConfig): Promise<CentroDeCusto[]> =>
			this.centroDeCustoServiceAdapter.findCentroDeCustoByLojaId(params, config)
}