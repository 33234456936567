import { Inject } from 'inversify-props'
import type { CentroDeCustoServiceAdapter } from './adapter/CentroDeCustoServiceAdapter'
import { CentroDeCusto } from '@/models/financeiro/Titulo'

export class SaveCentroDeCustoUseCase {
	@Inject('CentroDeCustoServiceAdapter')
	private centroDeCustoServiceAdapter!: CentroDeCustoServiceAdapter

	createCentroDeCusto = async (centroDeCusto: CentroDeCusto) => await this.centroDeCustoServiceAdapter.createCentroDeCusto(centroDeCusto)

	updateCentroDeCusto = async (centroDeCusto: CentroDeCusto) => await this.centroDeCustoServiceAdapter.updateCentroDeCusto(centroDeCusto)
}